import React from "react";
import { graphql } from "gatsby";
import tw from "twin.macro";
import { LocationsCard, CovidWarning, PageLayout } from "../components";

const LocationsPageTemplate = ({ data: { strapiBranchSettings } }) => {
  return (
    <PageLayout
      title={strapiBranchSettings.name}
      subtitle={strapiBranchSettings.secondaryName}
      mainStyles={tw`bg-dsrTeal-50`}
    >
      <CovidWarning grey />
      <div tw="mx-0 sm:mx-3">
        <LocationsCard {...strapiBranchSettings} headless />
      </div>
    </PageLayout>
  );
};

export default LocationsPageTemplate;

export const query = graphql`
  query locationsPageQuery($id: String!) {
    strapiBranchSettings(id: { eq: $id }) {
      contactDetails {
        addressLocality
        email
        postalCode
        streetAddress
        telephone
      }
      description
      googlePlacesId
      name
      openingHours {
        closingHour
        closingMinute
        day
        openingHour
        openingMinute
        closed
      }
      openingHoursNotes
      secondaryName
      group {
        socialLinks {
          facebookUrl
          instagramUrl
          twitterUrl
        }
      }
    }
  }
`;
